import React from 'react';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { breakpoints } from '../../utils/style';
//import Auth, { UserContext, CountCtx } from '../../components/Auth/Auth';
import Auth from '../../components/Auth/Auth';
import GameIframe from '../../components/Content/Games/Iframe';
import WithdrawCommentsStaticLoadmore from '../../components/Content/Withdraw/Comments/CommentsStaticLoadmore';
import Breadcrumbs from '../../components/shared/Breadcrumbs';

const WrapperDesktop = styled('div')`
  max-width: 1254px;
  margin: 0 auto;
  padding: 5px 15px;
  @media (max-width: ${breakpoints.md}) {
    padding: 0 15px;
  }
`;

const GameWrapper = styled('div')`
  .auth-wrapper {
    margin: 0;
    width: auto;
    float: right;
  }
  @media (max-width: ${breakpoints.sm}) {
      .orange{
        display:flex;
        flex-direction: column-reverse; 
        .game-title{
          margin-top:28px;
        }
      }
    .auth-wrapper {
      float: none;
      margin-top: -20px;
      text-align: justify;
      .button {
        width: 48%;
      }
    }
  }
`;

const Game = ({ pageContext }) => {
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  const game_name = pageContext.name;
  const game_code = pageContext.code;
  return (
    <Layout>
      <SEO
        title={game_name}
        description={game_name}
        pathname={'/' + game_code + '/'}
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        <GameWrapper>
          <h1 className="orange">
            <span className='game-title'>
              เกม {page_title}
            </span>
            <Auth />
          </h1>
          <GameIframe game_code={game_code} />
        </GameWrapper>
      </WrapperDesktop>
      <WrapperDesktop>
        <WithdrawCommentsStaticLoadmore />
      </WrapperDesktop>
    </Layout>
  );
};

export default Game;